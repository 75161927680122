<template>
    <div class="container py-3">
        <div class="row mt-2 mb-2 px-2">
            <Content url="skin-stories" title="Skin Stories" h="h1" />
        </div>
        <div class="row">
            <div class="col-md-8">
                <div v-if="!global.skinStories" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-else>
                    <div class="card mb-3 my-2 shadow" v-for="item in global.skinStories" :key="item.id">
                        <div class="row g-0">
                            <div class="col-md-3">
                                    <router-link :to="{ path: `/skin-story/${item.url}` }" class="stretched-link">
                                        <img class="card-img"  :src=item.image :alt=item.name>
                                    </router-link>
                            </div>
                            <div class="col-md-9">
                                <div class="card-body">
                                        <h3 class="card-title py-0">{{item.name}}</h3>
                                        <small class="text-muted py-0">by {{item.author}}</small>
                                        <p class="card-text py-3">{{item.shortDescription}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 card border-0">
                        <router-link to="skin-stories" class="btn btn-lg btn-primary float-right">More Skin Stories</router-link>
                    </div>
                </div>
            </div>

            

            <div class="col-md-4">
                <div class="card shadow mb-3 my-2" style="background-image: url('images/podcasts.jpg'); background-size: 100%">
                    <div class="card-body">
                            <h3 class="card-title py-0">Podcasts</h3>
                            <small class="text-muted py-0"></small>
                            <p class="card-text py-3" style="margin-right: 70px;">The Danish podcast “Hudlægens Bord” (The Skin Doctor’s Table) is for those citizens and other skin-curious people, who want to dig deeper into the understanding of the skin, and it conveys knowledge with challenging details and nuances in a lively way.</p>
                    
                        <div class="my-3 card border-0">
                            <router-link to="podcasts" class="btn btn-lg btn-primary float-right">More info here</router-link>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-3 my-2" style="background-image: url('images/global-edition.jpg'); background-size: 100%">
                    <div class="card-body">
                            <h3 class="card-title py-0">The Global Dermatology Edition</h3>
                            <small class="text-muted py-0"></small>
                            <p class="card-text py-3">We're launching 12 new SkinStories spotlighting skin diseases unique to certain regions. Seeking authors from high-prevalence countries. Please share suggestions and author info at hello@skin.science.</p>
                    
                        <div class="my-3 card border-0">
                            <router-link to="global-dermatology" class="btn btn-lg btn-primary float-right">More info here</router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import Content from '@/components/Content.vue';

    export default {
        name: 'SkinStoriesPaginated',
        components: { Content },
        computed: {
            ...mapState({
                global: state => state.global
            })
        }
    }
</script>
<style>
</style>