<template>
    <div class="container py-3">
        <div class="row mt-2 mb-2 px-2">
            <Content url="skin-stories" title="Skin Stories" h="h1" />
        </div>
        <div v-if="!skinStories" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else>
            <div class="d-flex py-2 justify-content-end">
                        <nav aria-label="Page navigation">
                            <ul class="pagination pagination justify-content-end">
                                <li class="page-item disabled">
                                    <span class="page-link">Page:</span>
                                </li>   
                                <li v-for="index in totalPages" :key="index" :class="index == currentPage ? 'active' : ''" class="page-item">
                                    <router-link :to="{ path: `/skin-stories/${index}` }" class="page-link">{{index}}</router-link>
                                </li>
                            </ul>
                        </nav>
                 
            </div>
            <div class="card mb-3 my-2 shadow" v-for="item in skinStories.items" :key="item.id">
                <div class="row g-0">
                    <div class="col-md-3">
                            <router-link :to="{ path: `/skin-story/${item.url}` }" class="stretched-link">
                                <img class="card-img"  :src=item.image :alt=item.name>
                            </router-link>
                    </div>
                    <div class="col-md-9">
                        <div class="card-body">
                                <h3 class="card-title py-0">{{item.name}}</h3>
                                <small class="text-muted py-0">by {{item.author}}</small>
                                <p class="card-text py-3">{{item.shortDescription}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex py-4 justify-content-end">
                        <nav aria-label="Page navigation">
                            <ul class="pagination pagination justify-content-end">
                                <li class="page-item disabled">
                                    <span class="page-link">Page:</span>
                                </li>   
                                <li v-for="index in totalPages" :key="index" :class="index == currentPage ? 'active' : ''" class="page-item">
                                    <router-link :to="{ path: `/skin-stories/${index}` }" class="page-link">{{index}}</router-link>
                                </li>
                            </ul>
                        </nav>
                 
            </div>
            
        </div>
    </div>
</template>
<script>
    import Content from '@/components/Content.vue';
    import { toastOptions } from '@/helpers/toastOptions.js';
    import axios from "axios";
    //import { mapState, mapActions } from 'vuex'

    export default {
        name: 'SkinStories',
        components: { Content },
       /*  computed: {
            ...mapState({
                global: state => state.global
            })
        }, */
        data() {
            return {
                skinStories: null,
                totalPages: 0,
                currentPage: 1
            }
        },
        methods: {
            //...mapActions('global', ['saveAllSkinStories']),
            getData(page) {
                this.currentPage = page;
                axios.get(process.env.VUE_APP_URL + `api/skinstories/paginated/${this.currentPage}`)
                        .then(response => {
                            this.skinStories = response.data;
                            this.totalPages = response.data.totalPages;
                        })
                        .catch(error => {
                            this.$toast(error.message, toastOptions.error);
                        })
            }
        },
        watch: {
            '$route.query.id': {
                handler: function () {
                    if (this.$route.params.id) {
                        this.getData(this.$route.params.id);
                    }
                    else
                    {
                        this.getData(1);
                    }
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>
<style>
</style>