<template>
    <div class="container py-3">
        <Content url="gpodcasts" title="Hudlægens Bord" h="h1"/>

        <div class="py-4">
            <iframe style="border-radius:12px" src="https://open.spotify.com/embed/show/7vQ91uucct7syp6RuuxkVr?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </div>

        <Content url="gpodcasts2" title="Links" h="h1"/>
    </div>
</template>
<script>
    import Content from '@/components/Content.vue';

    export default {
        name: 'Podcasts',
        components: { Content }
    }
</script>