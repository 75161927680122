import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import SearchProtein from '@/components/SearchProtein.vue'
import About from '@/components/About.vue'
import GlobalDermatology from '@/components/GlobalDermatology.vue'
import Resources from '@/components/Resources.vue'
import MassSpectrometry from '@/components/MassSpectrometry.vue'
import Contact from '@/components/Contact.vue'
import SkinStories from '@/components/SkinStories.vue'
import SkinStory from '@/components/SkinStory.vue'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'
import NotFound from '@/components/NotFound.vue'
import Dataset from '@/components/Dataset.vue'
import ImageManager from '@/components/ImageManager.vue'
import ProteinGraph from '@/components/ProteinGraph.vue'
import VolcanoPlot from '@/components/VolcanoPlot.vue'
import SearchProteinInDataset from '@/components/SearchProteinInDataset.vue'
import Podcasts from '@/components/Podcasts.vue'


const Login = () => import( '@/components/Login.vue')
const AdminDashboard = () => import( '@/components/AdminDashboard.vue')
const Admin = () => import( '@/components/Admin.vue')
const AdminDatasets = () => import( '@/components/AdminDatasets.vue')
const AdminDataset = () => import( '@/components/AdminDataset.vue')
const AdminDatasetEdit = () => import( '@/components/AdminDatasetEdit.vue')
const AdminCollaborators = () => import( '@/components/AdminCollaborators.vue')
const AdminSkinStories = () => import( '@/components/AdminSkinStories.vue')
const AdminContent = () => import( '@/components/AdminContent.vue')
const AdminContents = () => import( '@/components/AdminContents.vue')
const AdminCollaborator = () => import( '@/components/AdminCollaborator.vue')
const AdminSkinStory = () => import( '@/components/AdminSkinStory.vue')



const routes = [
    {
        path: "/", name: "Home", component: Home
    },
    {
        path: '/about', name: 'About', component: About
    },
    {
        path: '/resources', name: 'Resources', component: Resources
    },
    {
        path: '/mass-spectrometry', name: 'MassSpectrometry', component: MassSpectrometry
    },
    {
        path: '/contact', name: 'Contact', component: Contact
    },
    {
        path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy
    },
    {
        path: '/skin-stories/:id?', name: 'SkinStories', component: SkinStories
    },
    {
        path: '/skin-story/:url', name: 'SkinStory', component: SkinStory
    },
    {
        path: '/searchProtein', name: 'SearchProtein', component: SearchProtein
    },
    {
        path: '/proteinGraph', name: 'ProteinGraph', component: ProteinGraph
    },
    {
        path: '/searchDataset', name: 'SearchProteinInDataset', component: SearchProteinInDataset
    },
    {
        path: '/login', name: 'Login', component: Login
    },
    {
        path: '/dataset/:url', name: 'Dataset', component: Dataset
    },
    {
        path: '/admin', name: 'Admin', component: Admin
    },
    {
        path: '/admindashboard', name: 'AdminDashboard', component: AdminDashboard
    },
    {
        path: '/admindatasets', name: 'AdminDatasets', component: AdminDatasets
    },
    {
        path: '/admindataset', name: 'AdminDataset', component: AdminDataset
    },
    {
        path: '/admindatasetedit', name: 'AdminDatasetEdit', component: AdminDatasetEdit
    },
    {
        path: '/admincontents', name: 'AdminContents', component: AdminContents
    },
    {
        path: '/admincollaborators', name: 'AdminCollaborators', component: AdminCollaborators
    },
    {
        path: '/adminskinstories', name: 'AdminSkinStories', component: AdminSkinStories
    },
    {
        path: '/admincontent', name: 'AdminContent', component: AdminContent
    },
    {
        path: '/admincollaborator', name: 'AdminCollaborator', component: AdminCollaborator
    },
    {
        path: '/adminskinstory', name: 'AdminSkinStory', component: AdminSkinStory
    },
    {
        path: '/imagemanager', name: 'ImageManager', component: ImageManager
    },
    {
        path: '/volcanoPlot', name: 'VolcanoPlot', component: VolcanoPlot
    },
    {
        path: '/global-dermatology', name: 'GlobalDermatology', component: GlobalDermatology
    },
    {
        path: '/podcasts', name: 'Podcasts', component: Podcasts
    },
    {
        path: '/:pathMatch(.*)*', component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
});

router.beforeEach((to, from, next) => {
    const restrictedPages = ['/admin', '/dashboard'];
    const authRequired = restrictedPages.includes(to.path);
    const loggedIn = sessionStorage.getItem('user');

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    next();
})

export default router;